import { FC, useState } from "react"
import styled from "styled-components"
import Image from "next/image"
import Link from "next/link"
import { useInView } from "react-intersection-observer"

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { useShopifyProduct, useTracking, useCustomer, useLogToSentry } from "@hooks/index"

import { ContentfulProductWithShopifyData } from "@lib/types"
import type { ImageEdge, ProductVariant } from "shopify-storefront-api-typings"

import { Price } from "@components/common"
import { AddToBagButton } from "@components/ui"
import { decode } from "shopify-gid"
import ChevronRounded from "@svg/chevron-rounded.svg"

interface ThumbnailSliderProps {
  slides: ImageEdge[]
  slug: string
  priority: boolean
  trackProductClicked: () => void
}

const ThumbnailSlider: FC<ThumbnailSliderProps> = ({
  slides,
  slug,
  priority,
  trackProductClicked,
}) => {
  const [opacities, setOpacities] = useState<number[]>([])

  const [ref, slider] = useKeenSlider<HTMLUListElement>({
    slides: slides.length,
    initial: 0,
    loop: true,
    duration: 1500,
    move(s) {
      const new_opacities = s.details().positions.map(slide => slide.portion)
      setOpacities(new_opacities)
    },
  })

  const logToSentry = useLogToSentry()

  return (
    <Slider ref={ref} className="keen-slider">
      <ArrowButton type="button" $left onClick={() => slider.prev()}>
        <ChevronRounded />
      </ArrowButton>
      <ArrowButton type="button" onClick={() => slider.next()}>
        <ChevronRounded />
      </ArrowButton>
      {slides.map(({ node }, i) => {
        return (
          <Slide
            key={node.id}
            className={`fader__slide`}
            style={{ position: "absolute", opacity: opacities[i] }}
          >
            <Link href={`/products/${slug}`} passHref>
              <a onClick={trackProductClicked}>
                <Image
                  onError={e =>
                    logToSentry(
                      "Unable to load collection product image",
                      "productThumbnailImageError",
                      {}
                    )
                  }
                  src={node.transformedSrc}
                  width={260}
                  height={390}
                  alt={node.altText}
                  priority={priority && i === 0}
                  layout="responsive"
                />
              </a>
            </Link>
          </Slide>
        )
      })}
    </Slider>
  )
}

const Slider = styled.ul`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`

const ArrowButton = styled.button<{ $left?: boolean }>`
  visibility: hidden;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: ${({ $left }) => ($left ? 0 : null)};
  right: ${({ $left }) => (!$left ? 0 : null)};
  transform: translateY(-50%);
  padding: 2.1rem;
  outline: none;

  svg {
    transform: ${({ $left }) => ($left ? "rotate(180deg)" : null)};
  }
`

const Slide = styled.li`
  top: 0;
  width: 100%;
`

const getProductBadge = (
  firstVariant: ProductVariant,
  isProductSoldOut: boolean,
  inventoryQuantity: number,
  productBadgeImage: any,
  productBadge: string | null
) => {
  const { getPricePair } = useCustomer()
  if (isProductSoldOut) {
    return "Sold out"
  }

  if (productBadgeImage) {
    return null
  }

  if (productBadge) {
    return productBadge
  }

  if (inventoryQuantity > 0 && inventoryQuantity <= 7) {
    return `only ${inventoryQuantity} left`
  }

  if (getPricePair(firstVariant)?.compareAtPrice) {
    return "On Sale"
  }
}

interface ProductThumbnailProps {
  product: ContentfulProductWithShopifyData
}

export const ProductThumbnail: FC<ProductThumbnailProps> = ({ product }) => {
  const { category, color, title, slug, productBadge: pb, productBadgeImage } = product

  const { shopifyProduct } = useShopifyProduct(product)
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const { track } = useTracking()
  const totalVariants = shopifyProduct?.variants?.edges.length
  const allVariants = shopifyProduct?.variants?.edges
  const firstVariant = shopifyProduct?.variants?.edges[0]?.node
  const lastVariant = shopifyProduct?.variants?.edges[totalVariants - 1]?.node
  const priceIsRange = firstVariant?.priceV2.amount !== lastVariant?.priceV2.amount
  const isProductSoldOut = allVariants?.every((edge: any) => !edge.node.availableForSale)

  const lowestCompareAtPrice: number =
    shopifyProduct &&
    shopifyProduct?.variants?.edges
      ?.filter((edge: any) => edge.node.compareAtPriceV2?.amount)
      .map((edge: any) => Number(edge.node.compareAtPriceV2?.amount))
      .sort((a: number, b: number) => a - b)?.[0]

  const isDiscountPrice =
    shopifyProduct?.variants?.edges?.filter(
      (edge: any) => Number(edge.node.compareAtPriceV2?.amount) > Number(edge.node.priceV2?.amount)
    ).length > 0

  const discountPrice: number | undefined =
    shopifyProduct &&
    isDiscountPrice &&
    Math.min(
      ...shopifyProduct?.variants?.edges
        ?.filter(
          (edge: any) =>
            Number(edge.node.compareAtPriceV2?.amount) > Number(edge.node.priceV2?.amount)
        )
        .map((edge: any) => Number(edge.node.priceV2?.amount))
    )

  if (shopifyProduct?.title == "Bamboo Woven Hijab - Black") {
    console.log(shopifyProduct)
    console.log(lowestCompareAtPrice)
    console.log(isDiscountPrice)
  }

  const inventoryQuantity =
    shopifyProduct?.variants?.edges?.reduce(
      (a: number, v: any) => a + v?.node?.quantityAvailable!,
      0
    ) || 0

  const productBadgeText = getProductBadge(
    firstVariant,
    isProductSoldOut,
    inventoryQuantity,
    productBadgeImage,
    pb
  )

  const trackProductClicked = () =>
    track("Product Clicked", {
      product_id: decode(shopifyProduct?.id).id,
      name: title,
      category: category?.name,
      brand: "Haute Hijab",
      price: parseFloat(firstVariant?.priceV2?.amount),
      url: `https://www.hautehijab.com/products/${slug}`,
      image_url: shopifyProduct?.images?.edges[0]?.node?.transformedSrc,
    })

  return (
    <Article ref={ref}>
      <SliderContainer>
        {shopifyProduct && productBadgeText && (
          <BadgeWrapper>
            <ProductBadge isSoldOut={isProductSoldOut}>{productBadgeText}</ProductBadge>
          </BadgeWrapper>
        )}
        {productBadgeImage?.url && !isProductSoldOut && (
          <BadgeWrapper>
            <BadgeImage
              src={productBadgeImage.url}
              width={productBadgeImage.width}
              height={productBadgeImage.height}
              layout="fixed"
            />
          </BadgeWrapper>
        )}
        {shopifyProduct && (
          <>
            <ThumbnailSlider
              slides={shopifyProduct.images.edges}
              slug={slug}
              trackProductClicked={trackProductClicked}
              priority={inView}
            />
            <ButtonWrapper>
              <AddToBagButton
                trackingInfo={{
                  name: title,
                  url: `https://www.hautehijab.com/products/${slug}`,
                  category: category?.name,
                }}
                variants={shopifyProduct.variants.edges}
              />
            </ButtonWrapper>
          </>
        )}
      </SliderContainer>
      <Link href={`/products/${slug}`} passHref>
        <a onClick={trackProductClicked}>
          <ProductThumbnailTextWrapper>
            <h1>{title}</h1>
            {firstVariant && (
              <ProductThumbnailFirstVariant>
                {color?.name ? `${color.name} | ` : ""}
                <Price
                  price={lowestCompareAtPrice}
                  discountPrice={discountPrice}
                  variant={firstVariant}
                  isRange={priceIsRange}
                />
              </ProductThumbnailFirstVariant>
            )}
          </ProductThumbnailTextWrapper>
        </a>
      </Link>
    </Article>
  )
}

const Article = styled.article``

const BadgeWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 1rem;
  left: 1rem;
`

const BadgeImage = styled(Image)`
  border-radius: 50%;
`
const ProductBadge = styled.div<any>`
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  padding: 0.4rem;
  width: 4.8rem;
  height: 4.8rem;
  font-size: 0.9rem;
  line-height: 1.1rem;
  letter-spacing: 0.1rem;
  font-weight: ${({ isSoldOut }) => (isSoldOut ? "bold" : "normal")};
  background: ${({ theme, isSoldOut }) => (isSoldOut ? "black" : theme.color.white)};
  color: ${({ theme, isSoldOut }) => (isSoldOut ? "white" : theme.color.black)};
  border-radius: 50%;
`

const ButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1.4rem;
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 0 2rem;
    opacity: 0;
    transition: opacity ease-out 0.25s;
  }
`

const SliderContainer = styled.div`
  position: relative;
  padding-top: 150%;
  background: ${({ theme }) => theme.color.greyLight};
  margin: 0 0 1.4rem;

  &:hover {
    ${ArrowButton} {
      visibility: visible;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 2.5rem;

    &:hover {
      ${ButtonWrapper} {
        opacity: 1;
      }
    }
  }
`

export const ProductThumbnailTextWrapper = styled.div`
  white-space: pre-wrap;
  text-align: center;
  color: ${({ theme }) => theme.color.black};
  direction: initial;

  h1,
  h2,
  h3,
  h4 {
    margin: 0 0 1.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    // Need fixed height to ensure collection grid alignment
    height: ${({ theme }) => theme.productThumbnail.textWrapper.height}rem;
    overflow: hidden;

    h1,
    h2,
    h3,
    h4 {
      margin: 0 0 1.6rem;
      font-size: 2rem;
    }
  }
`

export const ProductThumbnailFirstVariant = styled.span`
  color: ${({ theme }) => theme.color.grey};
  font-size: 1.5rem;
  line-height: 2.6rem;
  font-weight: 400;
`
